<template>
  <div class="list-layout">
    <a-layout style="background: #ffffff">
      <!-- 群组树 -->
      <a-tooltip>
        <template #title>{{ isDisplayTree ? '隐藏群组树' : '显示群组树' }}</template>
        <a-icon
          v-if="groupTreeFunc"
          :type="treeDisplayIcon"
          @click="isDisplayTree = !isDisplayTree"
          style="
            font-size: 14px;
            color: #0a6dd9;
            cursor: pointer;
            position: relative;
            top: 24px;
            left: -8px;
            padding: 6px 4px 6px 8px;
            z-index: 99;
            border-radius: 8px;
          "
        ></a-icon>
      </a-tooltip>
      <a-layout-sider
        v-if="groupTreeFunc && isDisplayTree"
        style="
          border-right: 1px solid #e8e8e8;
          margin-right: 24px;
          background: #fff;
          position: relative;
        "
      >
        <group-tree
          :create-func="groupTreeFunc.createFunc"
          :delete-func="groupTreeFunc.deleteFunc"
          :get-list-func="groupTreeFunc.getListFunc"
          :update-func="groupTreeFunc.updateFunc"
          :canOperate="canOperate"
          @select="getGroupTable"
          :apiPermission="apiPermission"
        ></group-tree>
      </a-layout-sider>

      <a-layout-content>
        <!-- 顶部工具栏 -->
        <div
          v-if="toolsList.length"
          style="
            height: 32px;
            margin-bottom: 16px;
            font-size: 16px;
            line-height: 32px;
          "
        >
          <!-- 左边工具 (自定义) -->
          <a-space v-if="toolsList.find(item => item === 'leftTool')">
            <slot name="leftTool"></slot>
          </a-space>

          <!-- 右边工具栏 (默认提供：搜索、刷新、导出、设置表格列) -->
          <a-space size="middle" style="float: right">
            <!-- 自定义工具栏插槽 -->
            <slot name="rightTool"></slot>

            <a-input-group
              v-if="toolsList.find(item => item === 'search')"
              :compact="true"
            >
              <a-select
                v-model="searchType"
                :options="searchTypeOptions"
                style="min-width: 96px; border-right: 1px solid #fff"
              >
                <a-icon slot="suffixIcon" type="caret-down"></a-icon>
              </a-select>
              <a-input-search
                v-if="searchType !== 'os' && searchType !== 'group_id'"
                placeholder="请输入关键词"
                @search="search"
                style="width: 160px"
              ></a-input-search>
              <template v-else-if="searchType === 'os'">
                <source-select
                  source-type="os"
                  v-model="sourceSelect"
                  style="width: 200px"
                  :show-arrow="false"
                  :allow-clear="true"
                ></source-select>
                <a-button
                type="default"
                icon="search"
                @click="() => {
                  if (sourceSelect) search(sourceSelect.id)
                  else search('')
                }"
                ></a-button>
              </template>
              <template v-else-if="searchType === 'group_id'">
                <group-select
                  v-model="groups"
                  :multiple="false"
                  style="min-width: 240px;"
                  :source-type="sourceType"
                ></group-select>
                <a-button
                  type="default"
                  icon="search"
                  @click="searchGroup"
                  ></a-button>
              </template>
            </a-input-group>

            <reload-button
              v-if="toolsList.find(item => item === 'reload')"
              @click="fetch"
            ></reload-button>

            <export-button
              v-if="toolsList.find(item => item === 'export')"
              :columns="columnsAll"
              :default-selected="columnDefaultSelected"
              :export-func="exportFunc"
              :params="fetchParams"
              title="导出"
            ></export-button>

            <setting-button
              v-if="toolsList.find(item => item === 'setting')"
              :columns="columnsAll"
              :default-selected="columnDefaultSelected"
              @selected="updateColumns"
            ></setting-button>
          </a-space>
        </div>

        <!-- 表格 -->
        <!-- 表格选择通过 tableSelectData 抛出 [{ label: '', key:  '' }] -->
        <a-table
          class="custom-table scroll-hidden-table"
          ref="table"
          :components="components"
          :columns="columns"
          :data-source="dataSource"
          :loading="loading"
          :pagination="false"
          :rowKey="rowKey"
          @change="change"
          :scroll="{ x: scrollX }"
          :rowClassName="()=>'pdf-split-flag'"
          :row-selection="canSelect ? rowSelection : null"
          style="margin-bottom: 16px"
        >
          <!-- 自定义表头 -->
          <template
            v-for="item in columns.filter(column => column.slots !== undefined)"
            :slot="item.slots.title"
          >
            <slot :name="item.slots.title"></slot>
          </template>
          <!-- 自定义表格内容 -->
          <template
            v-for="item in columns.filter(
              column => column.scopedSlots !== undefined
            )"
            :slot="item.scopedSlots.customRender"
            slot-scope="text, record"
          >
            <slot
              :name="item.scopedSlots.customRender"
              :slotProps="{ text, record }"
            >
              <a-tooltip
                placement="topLeft"
                :title="text"
                :key="item.dataIndex"
              >
                {{ text || '-' }}
              </a-tooltip>
            </slot>
          </template>
        </a-table>

        <!-- 分页 -->
        <div v-if="showPagination" style="height: 36px">
          <template v-if="rowSelection.selectedRowKeys.length">
            <span style="line-height: 32px">
              {{ `已选中 ${rowSelection.selectedRowKeys.length} 条` }}
            </span>
            <a
              style="line-height: 32px; margin-left: 4px"
              @click="clearSelectData"
            >
              取消
            </a>
          </template>
          <a-pagination
            :current="fetchParams.page"
            :pageSize="fetchParams.page_size"
            :show-size-changer="true"
            :show-total="t => `共 ${t} 条`"
            :total="total"
            @change="
              page => {
                fetchParams.page = page
                fetch()
              }
            "
            @showSizeChange="
              (_, pageSize) => {
                fetchParams.page = 1
                fetchParams.page_size = pageSize
                fetch()
              }
            "
            style="float: right"
          ></a-pagination>
        </div>
      </a-layout-content>
    </a-layout>
  </div>
</template>

<script>
import GroupTree from '@/components/GroupTree'
import { updateCurrentPage } from '@/utils'
import { securityType } from '@/utils/const'
import ExportButton from '@/components/button/ExportButton'
import ReloadButton from '@/components/button/ReloadButton'
import SettingButton from '@/components/button/SettingButton'
import SourceSelect from '@/components/select/SourceSelect.vue'
import GroupSelect from '@/components/select/GroupSelect.vue'

export default {
  name: 'ListLayout',
  props: {
    groupTreeFunc: {
      type: Object,
      default: () => {}
    },
    getTableDataFunc: {
      type: Function
    },
    exportFunc: {
      type: Function
    },
    columnDefaultSelected: {
      type: Array
    },
    columnsAll: {
      type: Array,
      default: () => []
    },
    toolsList: {
      type: Array,
      default: () => []
    },
    refresh: {
      // 刷新表格数据
      type: [Boolean, Array]
    },
    tableOperationWidth: {
      // 表格操作列宽
      // 0 不显示表格操作列
      type: Number,
      default: 170
    },
    customerParams: {
      // 请求参数对象
      type: Object,
      default: () => {}
    },
    defaultSelected: {
      // 已选择的数据
      type: Array,
      default: () => []
    },
    canOperate: {
      // 能否进行群组树操作
      type: Boolean,
      default: true
    },
    searchTypeOptions: {
      // 搜索框数据源
      type: Array,
      default: () => [{ label: '名称', value: 'name' }]
    },
    inModal: {
      type: Boolean,
      default: false
    },
    canSelect: {
      type: Boolean,
      default: true
    },
    rowKey: {
      type: String,
      default: 'id'
    },
    // 函数id 判断是那种请求方式
    fetchId: {
      type: String
    },
    sourceType: {
      type: String,
      default: 'network_device'
    },
    apiPermission: {
      type: Object,
      default: () => {
        return {
          edit: '',
          delete: '',
          create: ''
        }
      }
    },
    canDragColumnWidth: {
      type: Boolean,
      default: false
    },
    showPagination: {
      default: true,
      type: Boolean
    }
  },
  components: {
    GroupTree,
    ExportButton,
    ReloadButton,
    SettingButton,
    SourceSelect,
    GroupSelect
  },
  data () {
    return {
      columns: [],
      dataSource: [],
      loading: false,
      total: 0,
      fetchParams: {
        page: 1,
        page_size: 20,
        ...this.customerParams
      },
      tableSelectData: [],
      rowSelection: {
        selectedRows: [],
        selectedRowKeys: [],
        onChange: (selectedRowKeys, selectedRows) => {
          this.rowSelection.selectedRowKeys = selectedRowKeys
          this.rowSelection.selectedRows = selectedRows

          if (this.tableSelectData.length) {
            this.tableSelectData = this.mergeArray(
              this.tableSelectData,
              this.rowSelection.selectedRows
            )
          } else {
            this.tableSelectData = selectedRows.map(item => {
              return {
                label: item.name,
                key: item.id
              }
            })
          }
          this.$emit('tableSelectData', this.tableSelectData)
        }
      },
      searchType: this.searchTypeOptions[0].value,
      sourceSelect: undefined,
      groups: undefined,
      isDisplayTree: true,
      components: {
        header: {}
      }
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => {
        sum += column.width
      })
      return sum
    },
    disabled () {
      return this.rowSelection.selectedRowKeys.length === 0
    },
    treeDisplayIcon () {
      return this.isDisplayTree ? 'caret-left' : 'caret-right'
    }
  },
  mounted () {
    this.isDisplayTree = false
    if (this.columnsAll.length) {
      this.updateColumns(
        this.columnsAll.filter(
          column => this.columnDefaultSelected.indexOf(column.dataIndex) !== -1
        )
      )
    }
    if (this.getTableDataFunc) {
      if (this.inModal) {
        // Table V4 已修复
        // 临时方案：窗口延迟抖动
        window.onresize = () => {
          this.fetch()
        }
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'))
          window.onresize = null
        }, 300)
      } else {
        this.fetch()
      }
    }
    // 同步已选择的数据
    this.tableSelectData = this.defaultSelected
    this.rowSelection.selectedRowKeys = this.defaultSelected.map(
      item => item.key
    )
    this.$emit('tableSelectData', this.tableSelectData)
  },
  methods: {
    fetch (update = false) {
      this.loading = true
      if (update) {
        this.fetchParams.page = updateCurrentPage(
          this.total,
          this.fetchParams.page,
          this.fetchParams.page_size
        )
      }
      if (this.fetchId) {
        this.getTableDataFunc(this.fetchId, this.fetchParams)
          .then(res => {
            const data = res.data
            this.dataSource = data.data
            this.total = data.total
            this.$emit('tableDataTotal', this.total)
          })
          .finally(() => {
            this.loading = false
            this.$emit('tableLoading')
          })
      } else {
        this.getTableDataFunc(this.fetchParams)
          .then(res => {
            const data = res.data
            this.dataSource = data.data
            this.total = data.total
            this.$emit('tableDataTotal', this.total)
          })
          .finally(() => {
            this.loading = false
            this.$emit('tableLoading')
          })
      }
    },
    getGroupTable (v) {
      this.fetchParams.page = 1
      this.fetchParams.group_id = v
      this.fetch()
    },
    updateColumns (columns) {
      this.columns = [...columns]
      if (this.tableOperationWidth !== 0) {
        this.columns.push({
          dataIndex: 'operation',
          title: '操作',
          align: 'center',
          fixed: 'right',
          width: this.tableOperationWidth,
          scopedSlots: {
            customRender: 'operation'
          }
        })
      }
    },
    change (_, filters) {
      this.columnsAll.forEach(item => {
        if (filters[item.dataIndex]) {
          item.filteredValue = filters[item.dataIndex]
        }
      })
      for (const key in filters) {
        if (Object.hasOwnProperty.call(filters, key)) {
          if (
            key === 'device_type' &&
            this.sourceType === 'security' &&
            !filters[key].length
          ) {
            this.fetchParams[key] = securityType
          } else this.fetchParams[key] = filters[key][0]
        }
      }
      this.fetchParams.page = 1
      this.fetch()
    },
    searchGroup () {
      let groupId = ''
      if (this.groups) groupId = this.groups.id
      this.search(groupId)
    },
    search (value) {
      if (value !== '') {
        this.fetchParams[this.searchType] = value
      } else {
        delete this.fetchParams[this.searchType]
      }
      this.fetchParams.page = 1
      this.rowSelection.selectedRowKeys = []
      this.fetch()
    },
    mergeArray (tableData, selectedRows) {
      let arr = [...tableData]
      for (const item of selectedRows) {
        const index = tableData.findIndex(i => i.key === item.id)
        if (index === -1) {
          arr.push({ label: item.name, key: item.id })
        }
      }
      arr = this.spliceArray(arr, this.rowSelection.selectedRowKeys)
      return arr
    },
    spliceArray (arr1, selectedRowKeys) {
      const arr = []
      for (const item of arr1) {
        if (selectedRowKeys.findIndex(i => i === item.key) !== -1) {
          arr.push(item)
        }
      }
      return arr
    },
    clearSelectData () {
      this.tableSelectData = []
      this.rowSelection.selectedRows = []
      this.rowSelection.selectedRowKeys = []
      this.$emit('tableSelectData', [])
    }
  },
  watch: {
    refresh (v) {
      this.fetch(true)
    },
    customerParams: {
      handler (v, ov) {
        // 添加新属性
        for (const key in v) {
          if (Object.hasOwnProperty.call(v, key)) {
            this.fetchParams[key] = v[key]
          }
        }
        // 删除旧属性
        for (const key in ov) {
          if (Object.hasOwnProperty.call(ov, key)) {
            if (!v[key] && this.fetchParams[key]) delete this.fetchParams[key]
          }
        }
        this.fetchParams.page = 1
        this.fetch()
      },
      deep: true
    },
    searchType (v, ov) {
      this.sourceSelect = undefined
      this.groups = undefined
      const arr = Object.keys(this.fetchParams)
      if (arr.findIndex(item => item === ov) !== -1) delete this.fetchParams[ov]
    },
    defaultSelected (v) {
      this.tableSelectData = v
      this.rowSelection.selectedRowKeys = v.map(item => item.key)
      this.$emit('tableSelectData', this.tableSelectData)
    }
  }
}
</script>
