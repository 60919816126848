<template>
  <div>
    <a
      @click="visible = true"
    >
      <a-icon class="icon" type="export"></a-icon>
      <span class="text">导出</span>
    </a>

    <a-modal
      :confirm-loading="loading"
      :title="title"
      :visible="visible"
      :width="720"
      @cancel="reset"
      @ok="ok"
    >
      <a-form-model
        ref="form"
        :label-col="{
          span: 4,
          style: {
            textAlign: 'left'
          }
        }"
        :model="form"
        :wrapper-col="{
          span: 20
        }"
      >
        <a-form-model-item label="导出字段" prop="columns">
          <a-checkbox
            :indeterminate="indeterminate"
            :checked="checked"
            @change="changeAll"
            >全选</a-checkbox
          >
          <a-button size="small" type="link" @click="change(defaultSelected)"
            >默认</a-button
          >
          <a-divider style="margin: 0 0 8px 0"></a-divider>
          <a-checkbox-group v-model="form.columns" @change="change">
            <a-row :gutter="16">
              <a-col v-for="option in options" :key="option.value" span="8">
                <a-checkbox :value="option.value">{{
                  option.label
                }}</a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </a-form-model-item>
        <a-form-model-item label="导出范围" prop="all">
          <range-radio v-model="form.all"></range-radio>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import RangeRadio from './modules/RangeRadio'

export default {
  name: 'ExportButton',
  components: {
    RangeRadio
  },
  props: {
    columns: {
      type: Array,
      required: true
    },
    defaultSelected: {
      type: Array,
      default: () => []
    },
    exportFunc: {
      type: Function,
      required: true
    },
    params: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      visible: false,
      loading: false,
      form: {
        columns: this.defaultSelected,
        all: true
      },
      indeterminate: false,
      checked: false
    }
  },
  computed: {
    options () {
      return this.columns.map(column => {
        return {
          label: column.title || column.slots?.name,
          value: column.dataIndex
        }
      })
    }
  },
  methods: {
    reset () {
      this.form = {
        columns: this.defaultSelected,
        all: true
      }
      Object.assign(this, {
        indeterminate: false,
        checked: false
      })
      this.$refs.form.resetFields()
      this.visible = false
    },
    changeAll (e) {
      this.form.columns = e.target.checked
        ? this.options.map(option => option.value)
        : []
      Object.assign(this, {
        indeterminate: false,
        checked: e.target.checked
      })
    },
    change (checkedList) {
      this.form.columns = checkedList
      Object.assign(this, {
        indeterminate:
          !!checkedList.length && checkedList.length < this.options.length,
        checked: checkedList.length === this.options.length
      })
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          this.exportFunc({ ...this.params, ...this.form })
            .then(res => {
              const blob = new Blob([res], { type: res.type })
              const url = window.URL.createObjectURL(blob)
              const a = document.createElement('a')
              a.href = url
              a.download = `${this.title}.xlsx`
              a.click()
              window.URL.revokeObjectURL(url)
              this.reset()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
a {
  color: #575757;
  user-select: none;

  &:hover {
    color: #0880ff;
  }

  .icon {
    font-size: 13px;
    vertical-align: text-bottom;
    padding-bottom: 1px;
  }
  .text {
    font-size: 12px;
    margin-left: 4px;
  }
}
</style>
