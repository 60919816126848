<template>
  <a-radio-group
    :options="options"
    :value="value"
    @change="change"
  ></a-radio-group>
</template>

<script>
export default {
  name: 'RangeRadio',
  props: {
    value: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      options: [
        {
          label: '导出全部',
          value: true
        },
        {
          label: '导出筛选',
          value: false
        }
      ]
    }
  },
  methods: {
    change (e) {
      this.$emit('input', e.target.value)
    }
  }
}
</script>
